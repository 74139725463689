.card-layout{
    border-radius: 12px;
background: var(--Primary-50, #F9F5FF);
padding: 32px;
box-shadow: none;
}
.new-card-text{
    color: var(--Gray-600, #475467);
text-align: center;

/* Text md/Regular */
font-family: "Inter";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
}
.new-card-title{
    color: var(--Gray-900, #101828);
text-align: center;

/* Text xl/Semibold */
font-family: "Inter";
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 30px; /* 150% */
}
.marqueemain{
    font-family: "Inter";
    font-size: 96px;
    font-style: normal;
    font-weight: 600;
    line-height: 90px;
    letter-spacing: -1.92px;
    color:#344054
}
.marqueetext{
    color: var(--Primary-600, #7F56D9);
font-family: "Inter";
font-size: 96px;
font-style: normal;
font-weight: 600;
line-height: 90px;
letter-spacing: -1.92px;
}