@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style-type: none;
}

:root {
  --primary: #7F56D9;
  --secondary: #F4EBFF;
  --text-primary: #101828;
  --text-secondary: #667085;
  --badge-bg: #ECFDF3;
  --badge-text: #027A48;
  --white: #fff;
  --dropdown-bg: rgb(252, 253, 251);
  --shadow: rgba(32, 7, 65, 0.14);
  --container: 135rem;
  --nav-height: 7rem;
}

html {
  font-family: 'Inter';
  font-size: 62.5%;
  font-style: normal;
}

body {
  font-size: 1.6rem;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("../../Assets/Fonts/Montserrat/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("../../Assets/Fonts/Montserrat/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("../../Assets/Fonts/Montserrat/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

.breadcrumb-item {
  color: var(--Gray-600, #475467);

  /* Text sm/Medium */
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}

a {
  text-decoration: none;
  color: #7F56D9;
}

ul {
  margin-bottom: 0px;
  padding-left: 0px;
}

a:hover {
  color: #101828;
}

.dropdown-item:hover {

  border-radius: 8px;
  background: #F9F5FF;


}

.layout {
  display: flex;
  padding: 50px 0px 50px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;
  align-self: stretch;
}


.container {
  max-width: var(--container);
  margin: 0 auto;
  padding: 0 1rem;
}

.course-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
}

.landingpage-header {
  background: url("../../Assets/Images/bg-img.png");
  background-size: cover;
  /* Ensures the background image covers the entire container */
  background-position: center center;
  /* Centers the background image */

}



.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--nav-height);
  position: relative;
  background: var(--white);
}


.nav_list {
  display: inline-flex;
  gap: 2rem;
  align-items: center;
  margin: 0 1.5rem;
  color: var(--Gray-600, #475467);

  /* Text md/Semibold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
}

.nav_action {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.nav_link,
.btn {
  display: flex;
  justify-content: center;
  gap: 1rem;
  font-weight: 500;
  color: var(--text-primary);
  align-items: center;
  font-family: "Inter";
}


.btn-primary:hover {
  border-radius: 8px;
  border: 1px solid var(--Primary-600, #7F56D9);
  background: var(--Primary-600, #7F56D9);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.icn-bc {
  border-radius: 28px;
  border: 8px solid var(--Primary-50, #F9F5FF);
  background: var(--Primary-100, #F4EBFF);

}

.btn-primary {
  display: inline-flex;
  color: var(--white);
  background: var(--primary);
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--Primary-600, #7F56D9);
  background: var(--Primary-600, #7F56D9);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--Base-White, #FFF);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}


.nav_toggle {
  cursor: pointer;
  display: none;
}

.nav_toggle ion-icon {
  font-size: 3.5rem;
  color: var(--text-primary);
}

.item-heading {
  color: var(--Primary-600, #7F56D9);

  /* Text sm/Semibold */
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
}

.dropdown {
  position: absolute;
  top: var(--nav-height);
  left: 0;
  width: 100%;
  background: var(--dropdown-bg);
  box-shadow: 0rem 0.2rem 0.5rem var(--shadow);
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  transition: all 0.5s ease-in;
  border-radius: 10px;
  border-radius: 12px;
  border: 1px solid var(--Gray-200, #EAECF0);
  background: var(--Base-White, #FFF);
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}

.dropdown-inner {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  gap: 1rem;
  padding: 2rem;
}

.nav_list_menu:hover .copyright-text {
  transition: all 0.5s ease-in;
  transform: rotate(180deg);
}

.nav_list_menu:hover .dropdown {
  clip-path: polygon(0 0, 100% 0, 100% 102%, 0 102%);
}

.item-list {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 3rem 0;
}

.item-img {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--secondary);
}

.item-list-info {
  position: relative;
  width: 100%;
}

.item-list-info h4 {
  color: var(--Gray-900, #101828);

  /* Text md/Semibold */
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
}

.item-list-info p {
  color: var(--Gray-600, #475467);

  /* Text sm/Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.info-badge {
  position: absolute;
  right: 1rem;
  top: 0;
  background: var(--badge-bg);
  padding: 0.1rem 0.5rem;
  border-radius: 1rem;
  color: var(--badge-text);
}



.dropdown {
  position: absolute;
  /* Set the position of the dropdown */
  top: 100%;
  /* Position it below the parent */
  left: 0;
  z-index: 1000;
  /* Set a higher z-index to ensure it appears above other elements */
  /* Hide the dropdown by default */
}


.whiteText {
  color: #fff;
}

/* Styles for small screens */
@media (max-width: 730px) {
  .redText {
    color: red;
  }
}

@media (max-width:730px) {
  .nav_toggle {
    display: block;

  }

  .nav_menu {
    position: absolute;
    top: var(--nav-height);
    left: 0;
    width: 100%;
    /* background: var(--dropdown-bg); */
    background: #0C111D;
    display: none;
  }

  .nav_menu.active {
    display: block;
  }

  .nav_list {
    display: block;
    margin: 2rem 0;
    text-align: center;
  }

  .nav_link {
    padding: 0 2rem;
    display: flex;
    justify-content: space-between;
    color: #fff;
  }

  .dropdown {
    top: 0;
    position: relative;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    box-shadow: none;
    height: 0;
    text-align: start;
    transition: all 0.5s ease-in;
  }

  .nav_list_menu:hover .dropdown {
    height: 100%;
    transition: all 0.5s ease-in;
  }
}

@media (max-width:365px) {
  .logo-img {
    width: 10rem;
  }

  .btn,
  .btn-primary {
    padding: 0.4rem 1rem;
  }
}

/* mui css */
/* strpper */
.css-2fdkz6 {
  color: var(--Gray-700, #344054) !important;
  text-align: center !important;
  font-family: "Inter" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important;
}

/* login */
.css-1km1ehz {
  color: var(--Gray-700, #344054) !important;
  font-family: "Inter" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important;
}

.css-4occqz:hover {
  background-color: transparent !important;
}

/* pagination */
.css-1qpp9n8 {
  color: var(--Gray-800, #1D2939) !important;
  text-align: center;

  /* Text sm/Medium */
  font-family: "Inter" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  /* 142.857% */
}


.search-filter {
  display: flex;
  flex-direction: column;

  align-items: stretch;
  width: 20%;
  border-radius: 8px;
  border-left: 1px solid #D0D5DD;
  border-right: 1px solid #D0D5DD;
  border-bottom: 1px solid #D0D5DD;
  border-top: none;
  height: 280px;
  overflow-y: scroll;



}

.searchlink {
  border-top: 1px solid #D0D5DD;
}

.searchlink:first-child {
  border-top: none;
}

.search-filter a {
  color: var(--Gray-900, #101828);
  font-size: 14px;
  font-family: "Inter";
  font-weight: 600;
}

.search-filter :hover {
  background-color: #CCE5FF;

}














/* footer */

.footer-section {
  background: #0C111D;
  position: relative;
}

.footer-cta {
  border-bottom: 1px solid #373636;
}

.single-cta i {
  color: #ff5e14;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}

.cta-text {
  padding-left: 15px;
  display: inline-block;
}

.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}

.cta-text span {
  color: var(--Gray-200, #EAECF0);

  /* Text md/Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.footer-content {
  position: relative;
  z-index: 2;
}

.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}

.footer-logo {
  margin-bottom: 30px;
}

.footer-logo img {
  max-width: 200px;
}

.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #7e7e7e;
  line-height: 28px;
}

.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
}

.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
}

.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}

.facebook-bg {
  background: #3B5998;
}

.twitter-bg {
  background: #55ACEE;
}

.google-bg {
  background: #DD4B39;
}

.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;

  font-family: 'Inter';
}

.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  /* left: 0; */
  bottom: -15px;
  height: 2px;
  /* width: 50px; */
  background: #ff5e14;
}

.footer-widget ul li {
  display: inline-block;
  /* float: left; */
  width: 100%;
  margin-bottom: 12px;

}

/* .footer-widget ul li a:hover{
    color: #ff5e14;
  } */
.footer-widget ul li a {
  color: #EAECF0;
  text-transform: capitalize;
  text-decoration: none;
  font-family: 'Inter';
}

.subscribe-form {
  position: relative;
  overflow: hidden;
}

.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  /* color: #fff; */
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #D0D5DD);
  background: var(--Base-White, #FFF);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.subscribe-form button {
  /* position: absolute; */
  right: 0;

  padding: 13px 20px;

  top: 0;
  color: var(--Base-White, #FFF);

  /* Text md/Semibold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  border-radius: 8px;
  border: 1px solid var(--Primary-600, #7F56D9);
  background: var(--Primary-600, #7F56D9);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}

.copyright-area {
  background: #0C111D;
  padding: 25px 0;
}

.copyright-text p {
  margin: 0;
  color: var(--Gray-300, #D0D5DD);

  /* Text md/Regular */
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.copyright-text p a {
  color: #ff5e14;
}

.footer-menu li {
  display: inline-block;
  margin-left: 20px;
  color: var(--Gray-300, #D0D5DD);

  /* Text md/Regular */
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

/* .footer-menu li:hover a{
    color: #ff5e14;
  } */
.footer-menu li a {
  color: var(--Gray-300, #D0D5DD);

  /* Text md/Regular */
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}


/* hero section */
.parrent {

  padding: 64px 0px 96px 0px;

  justify-content: center;

}

.ph {
  color: var(--Gray-900, #101828);

  /* Display xl/Semibold */
  font-family: Inter;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: 72px;
  /* 120% */
  letter-spacing: -1.2px;

}

.pt {
  color: var(--Gray-600, #475467);

  /* Text xl/Regular */
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* 150% */
  /* text-align: justify; */
}

/* client */
.section-header {
  color: var(--Gray-900, #101828);
  text-align: center;

  /* Display md/Semibold */
  font-family: "Inter";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  /* 122.222% */
  letter-spacing: -0.72px;
}


@keyframes slide {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}

.logos {
  overflow: hidden;
  padding: 60px 0;
  background: white;
  white-space: nowrap;
  position: relative;
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}

.logos:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}

.logos:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}

.logos-slide {
  display: inline-block;
  animation: slide 35s infinite linear;
  /* Adjust 35s to your preferred animation duration */
}

.logos-slide img {
  height: 50px;
  margin: 0 40px;
  flex: 0 0 auto
}

.subheading {
  color: var(--Primary-700, #6941C6);
  text-align: center;

  /* Text md/Semibold */
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
}

.text {
  color: var(--Gray-600, #475467);
  text-align: center;

  /* Text xl/Regular */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* 150% */
}

h5 {
  color: var(--Primary-600, #7F56D9);

  /* Text lg/Regular */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 155.556% */
}

h4 {
  color: var(--Gray-900, #101828);

  /* Display sm/Semibold */
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
  /* 126.667% */
}

p {
  color: var(--Gray-600, #475467);

  /* Text lg/Regular */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 155.556% */
}

.sectionpoint ul li {
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 155.556% */
  margin-top: 15px;
  padding-left: 30px;
  /* Adjust the padding to position the text after the icon */
  background: url("../../Assets/Images/check-circle.png") no-repeat left center;
  background-size: 24px;
  /* Adjust the size of the icon */
}

/* ready to explore */
.readytoexplore {
  border-radius: 24px;
  background: var(--gradient-primary-70060045-deg, linear-gradient(45deg, #6941C6 0%, #7F56D9 100%));

  /* Shadow/xl */
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
}

.exploretext {
  color: var(--Base-White, #FFF);

  /* Display md/Semibold */
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  /* 122.222% */
  letter-spacing: -0.72px;

}

.exploretextsub {
  color: var(--Primary-200, #E9D7FE);

  /* Text xl/Regular */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* 150% */
}

.btn_getintouch {
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #D0D5DD);
  background: var(--Base-White, #FFF);
  padding: 12px 20px;
  ;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--Gray-700, #344054);

  /* Text md/Semibold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
}

.addcartnav {
  /* display: flex; */
  padding: 10px 18px;
  /* justify-content: center;
align-items: center;
gap: 8px; */
  color: var(--Gray-600, #475467);

  /* Text md/Semibold */
  /* font-family: "Inter";
font-size: 16px;
font-style: normal; */
  /* font-weight: 600;
line-height: 24px;  */
  border: none;
  border-radius: 40px;
  height: 55px;
  background: var(--Gray-100, #F2F4F7);
}

.addcartnav sup {
  display: flex;
  width: 15px;
  height: 15px;
  padding: 12px 14px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;

  border-radius: 24px;
  background: var(--Primary-600, #7F56D9);
  top: 5px;

  color: var(--Base-White, #FFF);

  /* Text xs/Semibold */
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 150% */
}

.modal-100w {
  max-width: 85%;
  left: 5%;

}

.modal-100w .modal-dialog {
  width: 85%;
  max-width: 100%;
  margin: 1.75rem auto;
}

.modal-100w .modal-content {
  width: 85%;
  border-radius: 12px;
  padding: 24px;
}

.modal-title {
  color: var(--Gray-900, #101828);

  /* Display sm/Semibold */
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
  /* 126.667% */
}

.modal-header {
  border-bottom: none !important;
}

.input-group>.form-control,
.input-group>.form-select {
  border-radius: 8px;
  outline: none;
}

.get-image {

  float: inline-end;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px
}

.course-feature-img {
  margin-top: -100px;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float {
  margin-top: 4px;
}

/* responisive */



/* Extra Small Devices (phones) */
@media only screen and (max-width: 575px) {
  .learningpt-header {
    color: var(--Gray-900, #101828);

    /* Display sm/Semibold */
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;

  }

  .layout {
    gap: 0px;
    padding: 50px 0px 50px 0px;
  }

  .parrent {
    padding: 0px;
  }

  .course-feature-img {
    margin-top: 0px;
  }












  a:hover {
    color: #fff;
  }

  .logos-slide img {
    height: 50px;
    margin: 0 15px;
  }

  .get-image {

    float: inline-end;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    border-top-right-radius: 0px;

  }

  .logos:before {
    left: 0;
    background: transparent;
  }

  .logos:after {
    right: 0;
    background: transparent
  }

  .dropdown-inner {
    max-height: 500px;
    /* Set a fixed height for mobile */
    overflow-y: auto;
    /* Enable vertical scrolling if content exceeds the height */

  }

  .mobile-bottom-navigation {
    position: fixed;
    bottom: 0;
    width: 100%;
    /* height: 55px; */
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    display: flex;
    overflow-x: auto;
  }

  .navigation {
    background: var(--Base-White, #FFF);

    /* Shadow/sm */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
    justify-content: space-around;
    align-items: center;


  }

  .nav_action {
    display: flex;
    align-items: center;
    gap: 0;
  }

  .mobile__link {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    /* min-width: 50px; */
    /* overflow: hidden; */
    white-space: nowrap;

    font-size: 13px;
    color: #444444;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    transition: background-color 0.1s ease-in-out;
  }

  .course-card {
    display: contents;
  }

  .desktop-view {
    display: none;
  }

  .header-container {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .mobile-m-top {
    margin-top: 25px;
  }

  .mobile-parent-padding {
    padding: 25px 10px;
  }

  .mobile-flxdirection {
    display: flex;
    flex-direction: column;

  }

  .mobile-flxdirectionnew {
    display: flex;
    flex-direction: column-reverse;
  }

  .mobile-btn {
    text-align: center;
    margin-bottom: 15px;
    width: 100%;
  }

  .btn_getintouch {
    display: inline-flex;
    justify-content: center;
    width: 100%;
  }

  .library-button {
    display: inline-flex;
    justify-content: center;
    width: 100%;
  }

  .mobile-text {
    text-align: justify;
  }

  .exploretext {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
  }

  /* .parrent{
  padding: 0px;
} */
  .ph {
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    /* 122.222% */
    letter-spacing: -0.72px;
  }

  .main-header-title {
    color: var(--Gray-900, #101828);

    /* Display md/Semibold */
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    /* display: -webkit-box;
max-width: 100%;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
overflow: hidden; */
  }

  .main-header-sub-title {
    color: var(--Gray-600, #475467);

    /* Text xl/Regular */
    font-family: "Inter";

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* text-align: justify; */

    /* display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden; */

  }

  .course-card-content-mobile {
    display: flex;
    flex-wrap: wrap;
    column-gap: 35px
  }

  .course-card-content ul li {
    padding: 10px 0px;

    background: none;
    background-size: 24px;
    /* Adjust the size of the icon */
    /* Text md/Regular */
    font-family: "Inter";
    color: var(--Gray-700, #344054);


    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }


  .modal-100w {
    max-width: 100%;
    left: 0;

  }

  .modal-100w .modal-dialog {
    width: 100%;
    max-width: 100%;
    /* margin: 1.75rem auto; */
  }

  .modal-100w .modal-content {
    width: 100%;
    border-radius: 12px;
    padding: 24px;
  }

  .blog-text-container {
    position: static;
    padding: 10px;
    text-align: center;
    color: white;
  }

  /* footer */

  .footer-widget-heading h3 {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    position: relative;
    font-family: 'Inter';
  }

  .footer-widget {
    margin-bottom: 20px;
    border-bottom: 1px solid #373636;
  }


}

/* Small Devices (tablets - portrait) */
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .learningpt-header {
    color: var(--Gray-900, #101828);

    /* Display sm/Semibold */
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;

  }

  .mobile-flxdirectionnew {
    display: flex;
    flex-direction: column-reverse;
  }

  .parrent {
    padding: 0px;
  }

  .layout {
    gap: 0px;
    padding: 30px 0px 30px 0px;
  }

  a:hover {
    color: #fff;
  }

  .logos-slide img {
    height: 50px;
    margin: 0 15px;
  }

  .logos:before {
    left: 0;
    background: transparent;
  }

  .logos:after {
    right: 0;
    background: transparent
  }

  .modal-100w {
    max-width: 100%;
    left: 0;

  }

  .navigation {
    background: var(--Base-White, #FFF);

    /* Shadow/sm */
    /* box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10); */
    justify-content: space-around;
    align-items: center;
  }

  .header-container {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .nav_action {
    display: flex;
    align-items: center;
    gap: 0;
  }

  .modal-100w .modal-dialog {
    width: 100%;
    max-width: 100%;
    /* margin: 1.75rem auto; */
  }

  .modal-100w .modal-content {
    width: 100%;
    border-radius: 12px;
    padding: 24px;
  }

  .mobile-bottom-navigation {
    position: fixed;
    bottom: 0;
    width: 100%;
    /* height: 55px; */
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    /* display: flex; */
    /* overflow-x: auto; */
  }

  .course-card {
    display: contents;
  }

  .mobile__link {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    /* min-width: 50px;
    overflow: hidden; */
    white-space: nowrap;

    font-size: 13px;
    color: #444444;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    transition: background-color 0.1s ease-in-out;
  }

  .desktop-view {
    display: none;
  }


  .container {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 1rem;
  }

  .mobile-m-top {
    margin-top: 5px;
  }

  .mobile-parent-padding {
    padding: 25px 10px;
  }

  .mobile-flxdirection {
    display: flex;
    flex-direction: column;
  }

  .mobile-btn {

    text-align: center;
    margin-bottom: 15px;
    width: 100%;
  }

  .btn_getintouch {
    display: inline-flex;
    justify-content: center;
    width: 100%;
  }

  .library-button {
    display: inline-flex;
    justify-content: center;
    width: 100%;
  }



  .mobile-text {
    text-align: justify;
  }

  .exploretext {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
    padding-bottom: 10px;
  }

  /* .parrent{
  padding: 0px;
} */
  .ph {
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    /* 122.222% */
    letter-spacing: -0.72px;
  }

  .main-header-title {
    color: var(--Gray-900, #101828);

    /* Display md/Semibold */
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .main-header-sub-title {
    color: var(--Gray-600, #475467);

    /* Text xl/Regular */
    font-family: "Inter";

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

  }

  .course-card-content {
    display: flex;
    flex-wrap: wrap;
    column-gap: 35px
  }

  .course-card-content ul li {
    padding: 10px 0px;

    background: none;
    background-size: 24px;
    /* Adjust the size of the icon */
    /* Text md/Regular */
    font-family: "Inter";
    color: var(--Gray-700, #344054);

    /* Text sm/Medium */

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

  }

  .blog-text-container {
    position: static;
    padding: 10px;
    text-align: center;
    color: white;
  }

  .footer-widget-heading h3 {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    position: relative;
    font-family: 'Inter';
  }

  .footer-widget {
    margin-bottom: 20px;
    border-bottom: 1px solid #373636;
  }


}




/* Medium Devices (tablets - landscape) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .container {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 1rem;
  }


}


/* this is for specif training page  */
/* Large Devices (laptops/desktops) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  /* Styles for laptops/desktops go here */

  .mobile-view {
    display: none;
  }
}

/* Extra Large Devices (large desktops, wide screens) */
@media only screen and (min-width: 1200px) {

  /* Styles for large desktops go here */
  .mobile-view {
    display: none;
  }
}