/* FullPost.css */

.full-post-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Center content vertically and fill the screen */
  background-color: #f8f8f8; /* Background color for the entire page */
  margin-left: 300px; /* Add margin to the left */
  margin-right: 300px; /* Add margin to the right */
}
  
  .full-post-content {
    /* max-width: 800px;
    width: 90%; */
    background-color: #fff; /* Background color for the blog post content */
    /* padding: 20px;
   
    border-radius: 12px;
    box-shadow: 0 2px 4px -2px #1018280f, 0 4px 8px -2px #1018281a; */
    overflow-y: auto; /* Enable vertical scrolling if content overflows */
  }
  
  .full-post-cover-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px 10px 0 0; /* Rounded corners for the top of the image */
  }
  
  /* h2 {
    font-size: 28px;
    margin-top: 20px;
  } */
  
  /* p {
    font-size: 16px;
    line-height: 1.6;
  } */
  
  .full-post-author {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  
  .full-post-author-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .full-post-author-name {
    font-size: 18px;
  }
  
  .date {
    font-size: 14px;
    color: #888;
    margin-top: 10px;
  }


.blog-header{
  color: var(--Gray-900, #101828);

/* Display lg/Semibold */
font-family: "Inter";
font-size: 48px;
font-style: normal;
font-weight: 600;
line-height: 60px; /* 125% */
letter-spacing: -0.96px;
}

.blog-subheader{
  color: var(--Gray-600, #475467);

/* Text xl/Regular */
font-family: "Inter";
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 150% */
}
.copy-url-disp{
  visibility: none;
  visibility: collapse;
  position: absolute;
}
.btn-follow{
  align-items: center;
  background: #7F56D9;
 
  border: 1px solid #7F56D9;
 
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 #1018280d;
  color: var(--white);
  color: #fff;
  color: var(--Base-White, #fff);
  display: inline-flex;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  justify-content: center;
  line-height: 28px;
  margin-top: -8px;
  margin-left: 20px;

 
}











.comment-header-section{
  margin-left: 350px;
  margin-right: 350px;
  margin-top: -20px;
  border-top: 1px solid #EAECF0;

}
.numberoflikes{
  color: var(--Gray-600, #475467);

/* Text sm/Semibold */
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 142.857% */
}
.copylink{
  display: flex;
  cursor: pointer;
padding: 10px 16px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
border: 1px solid var(--Gray-300, #D0D5DD);
background: var(--Base-White, #FFF);

/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
color: var(--Gray-700, #344054);

/* Text sm/Semibold */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 142.857% */
}
.social_icon{
  cursor: pointer;
  display: flex;
padding: 10px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
border: 1px solid var(--Gray-300, #D0D5DD);
background: var(--Base-White, #FFF);

/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}


  
  /* Add more styling as needed */
      
  /* Responsive styles for small screens (e.g., mobile devices) */
@media (max-width: 768px) {
  .full-post-container {
    margin-left: 20px;
    margin-right: 20px;
  }
  .comment-header-section{
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
    border-top: 1px solid  #EAECF0;
  }

  /* Adjust font sizes and spacing for better readability on small screens */
  .full-post-content {
    padding: 10px;
  }

  .full-post-author-name {
    font-size: 16px;
  }

  .date {
    font-size: 12px;
    margin-top: 8px;
  }
}

.designation{
  color: var(--Gray-600, #475467);

/* Text md/Regular */
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
}