.form-control {
	font-size: 15px;
}
.form-control, .form-control:focus, .input-group-text {
	border-color: #e1e1e1;
}
.form-control, .btn {        
	border-radius: 3px;
}

.input-grp-style{
	border-radius: 8px;
border: 1px solid var(--Gray-300, #D0D5DD);
background: var(--Base-White, #FFF);

/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.signup-form {
	max-width: 400px;
	margin: 0 auto;
	padding: 30px 0;		
}
/* .signup-form form {
	color: #999;
	border-radius: 3px;
	margin-bottom: 15px;
	background: #fff;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
	padding: 30px;
} */

.signup-form h3:before, .signup-form h3:after {
	content: "";
	height: 2px;
	width: 40%;
	background: #d4d4d4;
	position: absolute;
	
	z-index: 2;
}
.signup-form h3 {
	color: #636363;
	margin: 0 0 15px;
	position: relative;
	text-align: center;
}
.signup-form h3:before {
	left: 0;
}
.signup-form h3:after {
	right: 0;
}	
.signup-form h2 {
	color: var(--Gray-900, #101828);
text-align: center;

/* Display sm/Semibold */
font-family: "Inter";
font-size: 30px;
font-style: normal;
font-weight: 600;
line-height: 38px; /* 126.667% */
}
.signup-form p{
    color: var(--Gray-600, #475467);
text-align: center;

/* Text md/Regular */
font-family: "Inter";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
}
.signup-form hr {
	margin: 0 -30px 20px;
}
.signup-form .form-group {
	margin-bottom: 15px;
}
.signup-form label {
	font-weight: normal;
	font-size: 15px;
}
.signup-form .form-control {
	min-height: 38px;
    border-radius: 8px;
    border: 1px solid var(--Gray-300, #D0D5DD);
    background: var(--Base-White, #FFF);
    
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}	
.signup-form .form-group label{
    color: var(--Gray-700, #344054);

/* Text sm/Medium */
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
padding-bottom: 10px;
}
.signup-form .input-group-addon {
	max-width: 42px;
	text-align: center;
}	
.signup-form .btn, .signup-form .btn:active {        
	display: flex;
padding: 10px 18px;
justify-content: center;
align-items: center;
gap: 8px;
align-self: stretch;
border-radius: 8px;
border: 1px solid var(--Primary-600, #7F56D9);
background: var(--Primary-600, #7F56D9);
width: 100%;
/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
color: var(--Base-White, #FFF);

/* Text md/Semibold */
font-family: "Inter";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
}

.signup-form .btn-login, .signup-form .btn-login:active { 
    display: flex;
    width: 100%;
padding: 10px 16px;
justify-content: center;
align-items: center;
gap: 12px;
align-self: stretch;       
    border-radius: 8px;
    border: 1px solid var(--Gray-300, #D0D5DD);
    background: var(--Base-White, #FFF);
    
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
color: var(--Gray-700, #344054);

/* Text md/Semibold */
font-family: "Inter";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */

}



.alreadyhv{
color: var(--Gray-600, #475467);

/* Text sm/Regular */
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
}

/* .signup-form .btn:hover, .signup-form .btn:focus {
	background: #179b81 !important;
} */
.signup-form a {
	color: var(--Primary-700, #6941C6);

/* Text sm/Semibold */
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 142.857% */
}
.signup-form a:hover {
	text-decoration: none;
}
.signup-form form a {
	color: var(--Primary-700, #6941C6);

/* Text sm/Semibold */
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 142.857% */
	text-decoration: none;
}	
.signup-form form a:hover {
	text-decoration: underline;
}
.signup-form .fa {
	font-size: 21px;
}
.signup-form .fa-paper-plane {
	font-size: 18px;
}
.signup-form .fa-check {
	color: #fff;
	left: 17px;
	top: 18px;
	font-size: 7px;
	position: absolute;
}
.oredersummary{
	color: var(--Gray-900, #101828);

/* Text xl/Semibold */
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 30px; /* 150% */
}

.ordersummaryname{
	color: var(--Gray-700, #344054);

/* Text sm/Medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
}
.ordersummaryvalue{
	color: var(--Gray-600, #475467);

/* Text sm/Regular */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
}
.totalamt{
	color: var(--Gray-900, #101828);

/* Text lg/Semibold */
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 28px; /* 155.556% */
}

.vertical-line {
	border-right: 1px solid #ccc; /* Adjust the color and thickness as needed */
	height: 100%; /* Adjust the height of the line based on your content */
	margin: 0 15px; /* Adjust the margin to separate the columns */
  }

  /* mycourse */
  .css-13cymwt-control{
	border-radius: 8px !important;
	
border: 1px solid var(--Gray-300, #D0D5DD) !important;
background: var(--Base-White, #FFF) !important;

/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
  }

  .css-t3ipsp-control{
	border-color: #D6BBFB !important;
	border-radius: 8px !important;
border: 1px solid var(--Primary-300, #D6BBFB) !important;
background: var(--Base-White, #FFF) !important;

/* Shadow/xs focused 4px primary-100 */
box-shadow: 0px 0px 0px 4px #F4EBFF, 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
  }
  .css-t3ipsp-control:hover{
	border-color: #D6BBFB !important;
	border-radius: 8px !important;
border: 1px solid var(--Primary-300, #D6BBFB) !important;
background: var(--Base-White, #FFF) !important;

/* Shadow/xs focused 4px primary-100 */
box-shadow: 0px 0px 0px 4px #F4EBFF, 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
  }

	.mycourse-header {
		background: var(--Green-800, #095C37);
		padding: 96px 0px;
		
	}

	.mycourse-title{
		color: var(--Base-White, #FFF);

/* Display lg/Semibold */
font-family: "Inter";
font-size: 48px;
font-style: normal;
font-weight: 600;
line-height: 60px; /* 125% */
letter-spacing: -0.96px;
	}
	.mycourse-subtitle{
		color: var(--Green-200, #AAF0C4);

/* Text xl/Regular */
font-family: "Inter";
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 150% */
	}

	.label-class{
		color: var(--Gray-700, #344054);

/* Text sm/Medium */
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
	}
	.filter-input{
		border-radius: 8px;
border: 1px solid var(--Gray-300, #D0D5DD);
background: var(--Base-White, #FFF);

/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
padding: 10px 14px;
	}

	.new-card-title{
	
			color: var(--Gray-900, #101828);
			font-family: "Inter";
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: 30px;
			display: -webkit-box;
			max-width: 100%;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
	}

	.progress-value{
		color: var(--Gray-700, #344054);

/* Text sm/Medium */
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
	}
	.popup-div{
		border-radius: 8px;
border: 1px solid var(--Gray-200, #EAECF0);
background: var(--Base-White, #FFF);

/* Shadow/lg */
box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
	}
  .popupcontent{
	display: flex;
padding: 9px 10px;
justify-content: center;
align-items: center;
gap: 12px;
color: var(--Gray-700, #344054);

/* Text sm/Medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
  }



  /* account setting */

  .accountsettings{
	color: var(--Gray-900, #101828);

/* Display sm/Semibold */
font-family: "Inter";
font-size: 30px;
font-style: normal;
font-weight: 600;
line-height: 38px; /* 126.667% */
  }
  
.tabset > input[type="radio"] {
    position: absolute;
    left: -200vw;
  }
  
  .tabset .tab-panel {
    display: none;
  }
  
  .tabset > input:first-child:checked ~ .tab-panels > .tab-panel:first-child,
  .tabset > input:nth-child(3):checked ~ .tab-panels > .tab-panel:nth-child(2),
  .tabset > input:nth-child(5):checked ~ .tab-panels > .tab-panel:nth-child(3),
  .tabset > input:nth-child(7):checked ~ .tab-panels > .tab-panel:nth-child(4),
  .tabset > input:nth-child(9):checked ~ .tab-panels > .tab-panel:nth-child(5),
  .tabset > input:nth-child(11):checked ~ .tab-panels > .tab-panel:nth-child(6) {
    display: block;
  }
  
  
  
  .tabset > label {
    position: relative;
    display: inline-block;
    padding: 12px 15px 9px;
   
    cursor: pointer;
    font-weight: 600;
    /* color: #6941c6; */
    font-family: 'inter';
	color: var(--Gray-500, #667085);

font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
  }
  
  .tabset > label::after {
    content: "";
    position: absolute;
    left: 15px;
    bottom: 5px;
    /* width: 22px; */
    height: 1px;
    color: #6941C6;
    background: #f9f5ff;
    border-bottom:  2px solid #6941C6;
    
  }
  
  .tabset > label:hover,
  .tabset > input:focus + label {
    color: #6941C6;
  }
  
  .tabset > label:hover::after,
  .tabset > input:focus + label::after,
  .tabset > input:checked + label::after {
    background: #6941C6;
  }
  
  .tabset > input:checked + label {
    
    border-bottom:  2px solid #6941C6;
   color:#6941C6
  }
  
  
  .tab-panels {
    padding: 5px 0 20px;
    border-top: 1px solid var(--Gray-200, #EAECF0);
  }

  .mydetails {
	color: var(--Gray-900, #101828);

/* Text lg/Semibold */
font-family: "Inter";
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 28px; /* 155.556% */
  }

  .mydetails-subtitle{
	color: var(--Gray-600, #475467);

/* Text sm/Regular */
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
  }


 .b-top{
	border-top: 1px solid var(--Gray-200, #EAECF0);
	padding: 25px 15px;
  }
  .details-label{
	color: var(--Gray-700, #344054);

/* Text sm/Semibold */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 142.857% */
  }
  .btn-save{
	border-radius: 8px;
border: 1px solid var(--Primary-600, #7F56D9);
background: var(--Primary-600, #7F56D9);

/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
color: var(--Base-White, #FFF);

/* Text md/Semibold */
font-family: "Inter";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
padding: 10px 18px;
justify-content: center;
align-items: center;
  }
  .btn-cancle{
	border-radius: 8px;
border: 1px solid var(--Gray-300, #D0D5DD);
background: var(--Base-White, #FFF);

/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

color: var(--Gray-700, #344054);

/* Text md/Semibold */
font-family: "Inter";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
padding: 10px 18px;
justify-content: center;
align-items: center;
  }


  /* upload file */
  .drop-zone {
	
	height: 150px;
	padding: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	
	border-radius: 12px;
border: 2px solid var(--Primary-600, #7F56D9);
background: var(--Base-White, #FFF);
  }

  .dropzone_icon{
	border-radius: 8px;
border: 1px solid var(--Gray-200, #EAECF0);
background: var(--Base-White, #FFF);

margin: auto;
display: flex;
width: 40px;
height: 40px;
padding: 10px;
justify-content: center;
align-items: center;


/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
  
  .drop-zone--over {
	border-style: solid;
  }
  
  .drop-zone__input {
	display: none;
  }
  
  .drop-zone__thumb {
	width: 100%;
	height: 100%;
	border-radius: 10px;
	overflow: hidden;
	background-color: #cccccc;
	background-size: cover;
	position: relative;
  }
  
  .drop-zone__thumb::after {
	content: attr(data-label);
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 5px 0;
	color: #ffffff;
	background: rgba(0, 0, 0, 0.75);
	font-size: 14px;
	text-align: center;
  }
  .click{
	color: var(--Primary-700, #6941C6);

/* Text sm/Semibold */
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 142.857% */
  }
  .drop-zone__prompt{
	color: var(--Gray-600, #475467);

/* Text sm/Regular */
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
  }
 


  /* profile pic */

  .avatar-upload {
    position: relative;
    /* max-width: 205px;
    margin: 50px auto; */
    .avatar-edit {
        position: absolute;
        right: 12px;
        z-index: 1;
        top: 10px;
        input {
            display: none;
            + label {
                display: inline-block;
                width: 34px;
                height: 34px;
                margin-bottom: 0;
                border-radius: 100%;
                background: #FFFFFF;
                border: 1px solid transparent;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
                cursor: pointer;
                font-weight: normal;
                transition: all .2s ease-in-out;
                &:hover {
                    background: #f1f1f1;
                    border-color: #d6d6d6;
                }
                &:after {
                    content: "\f040";
                    font-family: 'FontAwesome';
                    color: #757575;
                    position: absolute;
                    top: 10px;
                    left: 0;
                    right: 0;
                    text-align: center;
                    margin: auto;
                }
            }
        }
    }
    .avatar-preview {
        width: 120px;
        height: 120px;
        position: relative;
        border-radius: 100%;
        /* border: 6px solid #F8F8F8;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); */
        > div {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}


.pwd-container {
  position: relative;
  width: 100%;
}

/* .pwd-container .input {
  padding: 5px 30px 5px 10px;
  font-size: 20px;
} */

.pwd-container img {
  cursor: pointer;
  position: absolute;
  width: 20px;
  right: 8px;
  top: 10px;
}
  