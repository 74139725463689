.contactusheader{
    color: var(--Gray-900, #101828);

/* Display lg/Semibold */
font-family: "Inter";
font-size: 48px;
font-style: normal;
font-weight: 600;
line-height: 60px; /* 125% */
letter-spacing: -0.96px;
}

.contactussubheader{
    color: var(--Gray-900, #101828);

/* Display sm/Semibold */
font-family: "Inter";
font-size: 30px;
font-style: normal;
font-weight: 600;
line-height: 38px; /* 126.667% */
}