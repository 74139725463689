.maincomentdiv {
  /* display: flex; */
padding: 24px 32px;
/* flex-direction: column;
align-items: flex-start; */
gap: 16px;
align-self: stretch;
border-radius: 12px;
background: var(--Primary-50, #F9F5FF);
}

.comment-textarea{
  
padding: 12px 14px;

border-radius: 8px;
border: 1px solid var(--Gray-300, #D0D5DD);
background: var(--Base-White, #FFF);

/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

}


.comment {
  /* background-color: #f9f9f9; */
  border-left: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 10px;
  /* border-radius: 5px; */
}

.comment-user {
  color: var(--Gray-900, #101828);

  /* Text sm/Semibold */
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.comment-content {
  margin-top: 5px;
  color: var(--Gray-600, #475467);

/* Text sm/Regular */
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
}

.comment-date {
  color: var(--Gray-600, #475467);

  /* Text sm/Regular */
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.comment-actions {
  display: flex;
  gap: 10px;
}

.comment-reply-btn {
  color: var(--Primary-700, #6941C6);

  /* Text sm/Semibold */
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
  border: none;
  background-color: transparent;
 
}

/* .comment-reply-btn:hover {
  background-color: #0056b3;
} */




.comment-replies {
  margin-left: 20px;
  border-left: 2px solid #eee;
  padding-left: 15px;
}


.reply-form {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.reply-form textarea {
  width: 100%;
  /* padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px; */

  resize: vertical;
  outline: none;
}

/* .reply-form button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
} */

/* .reply-form button:hover {
  background-color: #218838;
} */

.new-comment-form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.new-comment-form textarea {
  width: 100%;
  /* padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px; */
  resize: vertical;
  outline: none;
}

.new-comment-form button {
  /* background-color: #17a2b8;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px; */

  color: var(--Base-White, #FFF);

/* Text md/Semibold */
font-family: "Inter";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
border-radius: 8px;
border: 1px solid var(--Primary-600, #7F56D9);
background: var(--Primary-600, #7F56D9);

/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
display: flex;
padding: 10px 18px;
justify-content: center;
align-items: center;
gap: 8px;
  cursor: pointer;
}

/* .new-comment-form button:hover {
  background-color: #138496;
} */

.btn-send{
  color: var(--Base-White, #FFF);

/* Text md/Semibold */
font-family: "Inter";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
border-radius: 8px;
border: 1px solid var(--Primary-600, #7F56D9);
background: var(--Primary-600, #7F56D9);

/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
display: flex;
padding: 10px 18px;
justify-content: center;
align-items: center;
gap: 8px;
}

.no_of_comments{
  color: var(--Gray-900, #101828);

  /* Text xl/Semibold */
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 150% */
}
