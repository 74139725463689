.react-player__preview{
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}



.allcourse-header{
    background: #0c111d;
    background-image: url('../../Assets/Images/Background\ pattern\ \(1\).png');
   
    background-position: center bottom;
    
    background-size:cover

}
.library-button {
    padding: 16px 28px;
justify-content: center;
align-items: center;
    border-radius: 8px;
border: 1px solid var(--Gray-300, #D0D5DD);
background: var(--Base-White, #FFF);

/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
color: var(--Gray-700, #344054);

/* Text lg/Semibold */
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 28px; /* 155.556% */
}
.section-heading{
    color: var(--Gray-900, #101828);
text-align: center;

/* Display md/Semibold */
font-family: "Inter";
font-size: 36px;
font-style: normal;
font-weight: 600;
line-height: 44px; /* 122.222% */
letter-spacing: -0.72px;
}
.section-sub-heading{
    color: var(--Gray-600, #475467);
text-align: center;

/* Text xl/Regular */
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 150% */
}
.course-layout{
    display: flex;
    padding: 52px 0px 0px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 14px;
    align-self: stretch;
}
.number{
    color: var(--Green-600, #099250);
    text-align: center;
    
    /* Display xl/Semibold */
    font-family: Inter;
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    line-height: 72px; /* 120% */
    letter-spacing: -1.2px;
}
.text-heading{
    color: var(--Primary-900, #42307D);
text-align: center;

/* Text lg/Medium */
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 28px; /* 155.556% */
}
.features-screen{
  

padding: 96px 0px;

}
.title h4{
    color: var(--Primary-700, #6941C6);

/* Text md/Semibold */
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
}
.feature-screen-header{
    color: var(--Gray-900, #101828);

/* Display md/Semibold */
font-family: Inter;
font-size: 36px;
font-style: normal;
font-weight: 600;
line-height: 44px; /* 122.222% */
letter-spacing: -0.72px;
}
.feature-screen-text{
    color: var(--Gray-600, #475467);

    /* Text xl/Regular */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 150% */
}
.main-feature{
    display: flex;

padding: 0px 32px;
align-items: center;
gap: 64px;
}
.f1h1{
    color: var(--Gray-900, #101828);

/* Text xl/Semibold */
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 30px; /* 150% */
}
.f1p1{
    color: var(--Gray-600, #475467);

/* Text md/Regular */
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
}
.btn-explore:hover{
  color: #FFF;
}
.btn-explore{
    display: flex;
padding: 12px 20px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
border: 1px solid var(--Primary-600, #7F56D9);
background: var(--Primary-600, #7F56D9);

/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
color: var(--Base-White, #FFF);

/* Text md/Semibold */
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
}
.card{
    border-radius: 12px;
  border: 1px solid var(--Gray-200, #EAECF0);
  
  /* Shadow/md */
  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10);
  }
  .card-top-btn{
    border-radius: 6px;
border: 1px solid var(--Green-300, #73E2A3);
background: var(--Green-100, #D3F8DF);
display: flex;
padding: 4px 10px;
justify-content: center;
align-items: center;
gap: 3px;
color: var(--Green-700, #087443);
text-align: center;

/* Text sm/Medium */
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
  }
.card-title{
    color: var(--Gray-900, #101828);
font-family: "Inter";
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 30px; /* 150% */
display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.total {
    color: var(--Gray-700, #344054);
text-align: center;

/* Text sm/Medium */
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
}
.card-text{
    display: flex;
padding: 4px 4px 4px 7px;

gap: 3px;
border-radius: 6px;
background: var(--Base-White, #FFF);
color: var(--Gray-700, #344054);
text-align: center;

/* Text sm/Medium */
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
}
.card-view-course{
    display: flex;
width: 100%;
padding: 16px 8px;
align-items: center;
gap: 12px;
border-radius: 8px;
color: var(--Primary-700, #6941C6);

/* Text lg/Semibold */
font-family: "Inter";
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 28px; /* 155.556% */
}

/* hv a question  */
.hv-question-container{
    border-radius: 16px;
background: var(--Green-800, #095C37);

padding: 64px;

}
.hv-question-title{
    color: var(--Base-White, #FFF);

    /* Display sm/Semibold */
    font-family: "Inter";
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px; /* 126.667% */
   
}
.hv-question-subtitile{
    color: var(--Green-200, #AAF0C4);

/* Text xl/Regular */
font-family: "Inter";
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 150% */
}

/* specific course */

.main-header{
    background: var(--Green-50, #EDFCF2);
    height: 336px;
padding: 32px 80px;
justify-content: space-between;
}

.library-header{
    border-radius: 12px;
background: var(--Green-50, #EDFCF2);
   
padding: 32px ;
justify-content: space-between;
}

.main-header-title{
    color: var(--Gray-900, #101828);

/* Display md/Semibold */
font-family: "Inter";
font-size: 36px;
font-style: normal;
font-weight: 600;
line-height: 44px; /* 122.222% */
letter-spacing: -0.72px;
display: -webkit-box;
max-width: 100%;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
overflow: hidden;
}
.main-header-sub-title{
    color: var(--Gray-600, #475467);

    /* Text xl/Regular */
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 150% */
   
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
   
}
.course-card{
    border-radius: 12px;
border: 1px solid var(--Gray-200, #EAECF0);
background: var(--Primary-50, #F9F5FF);

/* Shadow/md */
box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10);
}
.course-card-h{
    color: var(--Gray-900, #101828);

/* Display sm/Semibold */
font-family: "Inter";
font-size: 30px;
font-style: normal;
font-weight: 600;
line-height: 38px; /* 126.667% */
}
.course-card-h-offer{
    color: var(--Gray-600, #475467);
font-family: "Inter";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
text-decoration: line-through;
}
.course-card-h-discount{
    color: var(--Gray-900, #101828);

/* Text md/Semibold */
font-family: "Inter";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px;
}

.course-card-content ul li{
  padding: 10px 0px;
    color: var(--Primary-700, #6941C6);
    padding-left: 30px; /* Adjust the padding to position the text after the icon */
    background: url("../../Assets/Images/check-circle.png") no-repeat left center;
    background-size: 24px; /* Adjust the size of the icon */
    /* Text md/Regular */
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    gap:12px
}

.learningpt{
    border-radius: 12px;
border: 1px solid var(--Gray-200, #EAECF0);
background: var(--Base-White, #FFF);
}
.learningpt-header{
    color: var(--Gray-900, #101828);

/* Display sm/Semibold */
font-family: "Inter";
font-size: 30px;
font-style: normal;
font-weight: 600;

}
.addtocart{
    color: var(--Base-White, #FFF);

/* Text md/Semibold */
font-family: "Inter";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
    display: flex;
padding: 12px 20px;
justify-content: center;
align-items: center;
gap: 8px;
align-self: stretch;
    border-radius: 8px;
border: 1px solid var(--Primary-600, #7F56D9);
background: var(--Primary-600, #7F56D9);
width: 100%;

/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

/* accordian */

.assessment-objective-parrent{
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
background:  #F9F5FF;
padding: 16px;

/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.assessment-objective{
  color:  #101828;

  /* Text lg/Semibold */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
}
.assement-objective-collpase-icon{

}
.assessment-objective-content{
  color:  #344054;
  align-self: stretch;
/* Text md/Regular */
font-family: "Inter";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
border-bottom-left-radius: 8px;
border-bottom-right-radius: 8px;
background:  #F9F5FF;
padding: 16px;
text-align: justify;
/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.course-section{
    border-radius: 12px;
background: var(--Base-White, #FFF);
padding: 24px 0px;
}
.requirment ul li {
    list-style-type: disc; /* Change to 'circle' or 'square' if you prefer different bullet styles */
    list-style-position: inside; /* Ensure dots are inside the list item */
    color: var(--Gray-600, #475467);
    gap: 24px;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .removebtn {
    display: flex;
justify-content: flex-end;
align-items: center;
gap: 8px;
align-self: stretch;
color: var(--Primary-700, #6941C6);

/* Text md/Semibold */
font-family: "Inter";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
border: none;
background: transparent;
  }
  .checkout{
    display: flex;
    /* border-radius: 12px; */
    background: var(--Base-White, #FFF);
flex-direction: column;
align-items: flex-start;
  }
  .checkoutamt{
    color: var(--Gray-900, #101828);

/* Display sm/Semibold */
font-family: "Inter";
font-size: 30px;
font-style: normal;
font-weight: 600;
line-height: 38px; /* 126.667% */
  }
  .checkoutbtn{
    display: flex;
padding: 10px 18px;
justify-content: center;
align-items: center;
gap: 8px;
align-self: stretch;
border-radius: 8px;
border: 1px solid var(--Primary-600, #7F56D9);
background: var(--Primary-600, #7F56D9);
color: var(--Base-White, #FFF);

/* Text md/Semibold */
font-family: "Inter";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .promotion{
    display: flex;
flex-direction: column;
align-items: flex-start;
gap: 6px;
align-self: stretch;
  }
  .promotion p{
    color: var(--Gray-600, #475467);

/* Text md/Medium */
font-family: "Inter";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
  }
  .promotion span{
    color: var(--Gray-600, #475467);

/* Text sm/Regular */
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
  }
  .applycoupn{
    display: flex;
    padding: 10px 14px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
border: 1px solid var(--Gray-300, #D0D5DD);
background: var(--Base-White, #FFF);

color: var(--Gray-500, #667085);

/* Text md/Regular */
font-family: "Inter";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */

/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
  .applycoupn-btn{
    display: flex;
padding: 10px 16px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
border: 1px solid var(--Primary-200, #E9D7FE);
background: var(--Primary-50, #F9F5FF);

/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
color: var(--Primary-700, #6941C6);

/* Text sm/Semibold */
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 142.857% */
  }


  /* filter sidebar */
  .filter-sidebar-main{
  
background: var(--Base-White, #FFF);

/* Shadow/xl */
box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  }

  .filter-sidebar-list{
    color: var(--Gray-700, #344054);

/* Text md/Medium */
font-family: "Inter";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */

padding: 8px 12px;

  }




  .mainsidebar{
    overflow-y: scroll;
  }
  .mainsidebar::-webkit-scrollbar{
  display: none;
  }

  .filter-sidebar-submenu{
  
padding: 8px 12px;


border-top: 1px solid #EDFCF2;
color: var(--Gray-900, #101828);

/* Text md/Regular */
font-family: "Inter";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
  }

  /* .filter-sidebar-submenu:active,
.filter-sidebar-submenu:focus {
  background-color: red;
} */
  

  .morefilter{
    border-radius: 8px;
border: 1px solid var(--Primary-200, #E9D7FE);
background: var(--Primary-50, #F9F5FF);
display: flex;
padding: 10px 18px;
justify-content: center;
align-items: center;
gap: 8px;
color: var(--Primary-700, #6941C6);

/* Text md/Semibold */
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .css-8kck46 {
    border: none !important;
    background: transparent !important;
  }
  .css-zpmn9m{
    /* border-bottom: none !important; */
    color: var(--Gray-700, #344054) !important;

/* Text md/Medium */
font-family: "Inter" !important;
font-size: 16px !important;
font-style: normal !important;
font-weight: 500 !important;
line-height: 24px !important; /* 150% */
background-color: transparent !important;
margin-bottom: 0px !important;
/* padding: 0px !important; */
  }
  
  .ribbon-2 {
    --f: 1px; /* control the folded part*/
    --r: 18px; /* control the ribbon shape */
    --t: 10px; /* the top offset */
    
    position: absolute;
    inset: var(--t) calc(-1*var(--f)) auto auto;
    padding: 5px 5px var(--f) calc(10px + var(--r));
    clip-path: 
      polygon(0 0,100% 0,100% calc(100% - var(--f)),calc(100% - var(--f)) 100%,
        calc(100% - var(--f)) calc(100% - var(--f)),0 calc(100% - var(--f)),
        var(--r) calc(50% - var(--f)/2));
    background: #7F56D9;
    box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
    color:  #FFF;
   
    margin-top: -10px;
    


  }
  .instled{
    color: var(--Base-White, #FFF);

/* Text sm/Semibold */
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 142.857% */
  }

  .instled-date{
    color: #fff;

/* Text sm/Regular */
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
  }
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root{
  /* color: var(--Gray-600, #475467) !important;
    font-family: "Inter" !important ;
    font-size: 16px !important ;
   
    font-weight: 600 !important ; */
    color: var(--Gray-700, #344054) !important;

/* Text sm/Medium */
font-family: "Inter" !important;
font-size: 14px !important;
font-style: normal !important;
font-weight: 500 !important;
line-height: 20px !important; /* 142.857% */
}

.css-6hp17o-MuiList-root-MuiMenu-list{
  
  border-radius: 8px !important;
  border: 1px solid var(--Gray-200, #EAECF0) !important;
  background: var(--Base-White, #FFF) !important;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08) !important;
}

/* wizard */

.css-1hv8oq8-MuiStepLabel-label.Mui-completed{
  color: var(--Primary-700, #6941C6) !important;
text-align: center !important;

/* Text sm/Semibold */
font-family: "Inter" !important;
font-size: 14px !important;
font-style: normal !important;
font-weight: 600 !important;
line-height: 20px !important; /* 142.857% */
}

.css-1hv8oq8-MuiStepLabel-label.Mui-active{
  color: var(--Gray-700, #344054) !important;
text-align: center !important;

/* Text sm/Semibold */
font-family: "Inter" !important;
font-size: 14px !important;
font-style: normal !important;
font-weight: 600 !important;
line-height: 20px !important; /* 142.857% */
}
.css-1hv8oq8-MuiStepLabel-label{
  color: var(--Gray-700, #344054) !important;
  text-align: center !important;
  
  /* Text sm/Semibold */
  font-family: "Inter" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important; /* 142.857% */
}
.css-j41d1b-MuiStepConnector-root.Mui-active .MuiStepConnector-line{
  border-color: #7f56d9 !important;
}
/* .css-eqeipb-MuiPaper-root-MuiPopover-paper-MuiMenu-paper{
  filter: none !important;
  border-radius: 8px !important;
border: 1px solid var(--Gray-200, #EAECF0) !important;
background: var(--Base-White, #FFF) !important;


box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08) !important;
} */


.acrdiancontent{
  color: var(--Primary-900, #42307D);

/* Text md/Regular */
font-family: "Inter";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
}
.reset{

padding: 10px 18px;
justify-content: center;
align-items: center;
gap: 8px;
color: var(--Gray-600, #475467);

/* Text md/Semibold */
font-family: "Inter";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
border-radius: 8px;
border: none;
background: transparent;
}



/* pagination */
.css-rppfq7-MuiButtonBase-root-MuiPaginationItem-root{
  font-size: 18px !important;
  font-family: Inter !important;
}

.css-1scal0h-MuiPaginationItem-root{
  font-size: 18px !important;
  font-family: Inter !important;
}

.page_button{
  color: var(--Gray-600, #475467);

/* Text sm/Semibold */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 142.857% */
border: none;
background: transparent;
}


/* customzie course */

.customize-course-title{
  color: var(--Gray-600, #475467);

/* Text lg/Regular */
font-family: "Inter";
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 155.556% */
align-self: stretch;
}
.SelectModule{
  color: var(--Gray-700, #344054);

/* Text md/Medium */
font-family: "Inter";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
}


.selectmoduletype {
  /* display: flex; */
  align-items: center; /* Align items vertically */
  width: 100%;
  padding: 16px;
align-items: center;
gap: 4px;
align-self: stretch;
border-radius: 12px;
border: 1px solid var(--Gray-200, #EAECF0);
background: var(--Base-White, #FFF);
margin-top: 15px;
}
.selectmoduletype label {
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  margin: 0px 10px;
}
.selectmoduletype input[type="checkbox"] {
  appearance: none; /* Hide default appearance */
  -webkit-appearance: none; /* For older versions of WebKit */
  -moz-appearance: none; /* For older versions of Firefox */
  border-radius: 50%; /* Make checkbox appear as a circle */
  width: 20px; /* Adjust the size as needed */
  height: 20px; /* Adjust the size as needed */
  border: 2px solid #ccc; /* Border color for the circle */
  outline: none; /* Remove focus outline */
  cursor: pointer; /* Show pointer cursor */
  position: relative;
}

.selectmoduletype input[type="checkbox"]:checked {
  border-radius: 8px;
border: 1px solid var(--Primary-600, #7F56D9);
background: var(--Primary-600, #7F56D9);

/* Focus ring/4px primary-100 */
box-shadow: 0px 0px 0px 4px #F4EBFF; /* Background color when checked */
}




.selectmoduletype input[type="checkbox"]:checked::before {
  content: '\2713'; /* Checkmark character */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white; /* Checkmark color */
  font-size: 16px; /* Adjust size as needed */
}

.selectedmodule_icon{
  border-radius: 28px;
border: 4px solid var(--Primary-50, #F9F5FF);
background: var(--Primary-100, #F4EBFF);
mix-blend-mode: multiply;

padding: 8px;
justify-content: center;
align-items: center;

}

.course-selected{
  color: var(--Gray-600, #475467);

  /* Text sm/Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.course-selected span{
  color: var(--Gray-600, #475467);

/* Text sm/Semibold */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px;
}
.btn-cancle{
  display: flex;
padding: 12px 20px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
border: 1px solid var(--Gray-300, #D0D5DD);
background: var(--Base-White, #FFF);

/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
color: var(--Gray-700, #344054);

/* Text md/Semibold */
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
}

.assement-objective-collpase-icon {
  border-radius: 28px;
  border: 1px solid var(--Gray-200, #EAECF0);
  background: rgba(255, 255, 255, 0.90);
  backdrop-filter: blur(4px);
  display: flex;
  width: 48px;
  height: 48px;
  /* transform: rotate(-90deg); */
  justify-content: center;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}
.customize{
  height:320px; 
  overflow-y:scroll;
   overflow-x: hidden;
}
.customize::-webkit-scrollbar {
  width: 8px;
}


.customize::-webkit-scrollbar-thumb {
 
  border-radius: 8px;
background: var(--Gray-200, #EAECF0);
}
